<script>
import formSelectWrapCascader from './form-select-wrap-cascader'
import baseDataServices from '@/services/baseDataServices'
import { Toast } from 'vant'
import { i18n } from '@/main'
export default {
  resolve: null,
  reject: null,
  components: {
    formSelectWrapCascader
  },
  data() {
    return {
      list: [],
      showPicker: false,
      cars: [],
      code: '',
      loading: false,
      empty: false,
      initData:[],
      showThreeLevel: false,
      selectMaxLevel: '', // 可选择的最大车型级数
      brandDisabled: false,
      i18n
    }
  },
  methods: {
    loadData(params) {
      // 编辑时回显车型信息
      const { initCarInfo={}, requestList } = params
      if (initCarInfo.seriesCode) {
        const { seriesCode, modelCode } = initCarInfo
        this.initData = [ seriesCode, modelCode]
      }
      const toast = Toast.loading({
        message: i18n.t('loading'),
        forbidClick: true
      })
      this.loading = true
      if (requestList && Array.isArray(requestList)) {
        this.list = requestList
        toast.clear()
        this.loading = false
        return false
      }
      baseDataServices.getAllSeriesModels({})
        .then(data => {
          data.forEach(item => {
            if (!item) return false
            const { name: seriesName, code: seriesCode, nameEn: seriesNameEn } = item
            const itemData = { text: seriesName, seriesCode, seriesName, children: [], seriesNameEn }
            if (!item.models.length) {
              return this.list.push(itemData)
            }
            item.models.forEach(series => {
              const { name: modelName, code: modelCode, nameEn: modelNameEn } = series
              const modelData = { text: modelName, modelName, modelCode, children: [], modelNameEn }
              return itemData.children.push(modelData)
            })
            this.list.push(itemData)
          })
        }).finally(() => {
          toast.clear()
          this.loading = false
        })
    },
    onConfirm(value) {
      const data = {}
      // 判断 是否选到了最底层
      // if (value.length > 0) {
      //   if (value[value.length - 1].children && value[value.length - 1].children.length) {
      //     Toast('请完成全部选择')
      //     return false
      //   }
      // }
      value.forEach(item => Object.assign(data, item))
      delete data.children
      this.resolve({
        ...data,
        showThreeLevel: this.showThreeLevel
      })
      this.showPicker = false
    },
    cancel() {
      this.reject('cancel')
      this.showPicker = false
    }
  }
}
</script>
<template>
<form-select-wrap-cascader
  :columns="list"
  :showPicker="showPicker"
  :initData="initData"
  :title="i18n.t('请选择车型')"
  :brandDisabled="brandDisabled"
  :showThreeLevel="showThreeLevel"
  :loading="loading"
  :empty="empty"
  :selectMaxLevel="selectMaxLevel"
  @confirm="onConfirm"
  @cancel="cancel">
</form-select-wrap-cascader>
</template>
